<template>
  <v-card
    class="mx-auto"
    max-width="300"
    tile
    min-width="50%"
  >
    <v-card-title>{{titulo}}</v-card-title>
    <v-card-text>
        <v-text-field label="Entre com uma nova opção:" v-model="newItem"></v-text-field>
        <v-btn style="margin-right: 10px;margin-left: 10px;"
            
            @click="addNew">
            <v-icon dark>mdi-plus</v-icon>Adicionar
        </v-btn>
        <v-btn style="margin-right: 10px;margin-left: 10px;"
           
            @click="remove">
            <v-icon dark>mdi-minus</v-icon>Remover
        </v-btn>
        <!-- Respostas Aceitas -->
        <v-radio-group v-model="selectedItem">
            <v-radio
                v-for="(prop,i) in editedKeys"
                :key="i"
                :label="`${editedObject[prop]}`"
                :value="prop"
                @click="setAnswer"
            ></v-radio>
        </v-radio-group>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="$emit('save')"
        >
            OK
        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="$emit('cancel')"
        >
            Cancelar
        </v-btn>
    </v-card-actions>    
  </v-card>
</template>

<script>
export default {
    props:["titulo","editedObject","answer"],
    data: () => ({
        editedKeys: [],
        selectedItem: null,
        newItem: ''
    }),
    methods: {
        setAnswer() {
            this.$emit('set',this.selectedItem)
        },
        addNew() {
            this.$emit('new',this.newItem)
            this.setKeys()
            this.newItem = ''
        },
        remove() {
            this.$emit('del',this.selectedItem)
            this.setKeys()
        },
        setKeys() {
            this.editedKeys = []
            if (this.editedObject) {
                this.editedKeys =  Object.keys(this.editedObject)
                for (var i = 0; i < this.editedKeys.length; i++) {
                    var aKey = this.editedKeys[i]
                    //console.error(this.editedObject[aKey]+'=='+this.answer[aKey])
                    if (this.editedObject[aKey] == this.answer[aKey]) {
                        this.selectedItem = aKey
                        break
                    }
                }
            }
            return this.editedKeys
        }
    },
    mounted() {
      this.setKeys()
    },
    watch: {
        editedObject() {
            this.setKeys()
        }
    },
    computed: {
    }
}
</script>
