<template>
    <v-card class="mx-auto mt-10" outlined>
        <v-card-title>{{titulo}} (Perguntas)</v-card-title>
        <v-data-table :headers="headers" 
            :items="perguntas" 
            :search="search" class="elevation-1" fixed-header height="350px">
            <v-divider inset></v-divider>
            <template v-slot:top>
            <v-toolbar flat color="white">
                <div class="d-flex w-100">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense outlined single-line hide-details></v-text-field>
                <v-btn
                    color="primary"
                    class="ml-2 white--text"
                    @click="addNew">
                    <v-icon dark>mdi-plus</v-icon>Add
                </v-btn>
                </div>
            </v-toolbar>
            </template>
            <template v-slot:[`item.texto`]="{ item }">
                <v-text-field v-model="editedItem.texto" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{item.texto}}</span>
            </template>
            <template v-slot:[`item.respostasaceitas`]="{ item }">
                <span v-if="item.id === editedItem.id">{{editedItem.respostasaceitas}}</span>
                <span v-else>{{item.respostasaceitas}}</span>
            </template>
            <template v-slot:[`item.respostascorretas`]="{ item }">
                <span v-if="item.id === editedItem.id">{{editedItem.respostascorretas}}</span>
                <span v-else>{{item.respostascorretas}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div v-if="item.id === editedItem.id">
                    <v-icon color="red" class="mr-3" @click="close">
                        mdi-window-close
                    </v-icon>
                    <v-icon color="green"  @click="save">
                        mdi-content-save
                    </v-icon>
                </div>
                <div v-else>
                    <v-icon color="green" class="mr-3" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon color="red" @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <!-- <v-icon color="red" @click="editarRespostasAceitas(item)" title="Editar Respostas Aceitas"> -->
                    <v-icon color="red" @click="editarObjeto(item)" title="Editar Respostas Aceitas">
                        mdi-playlist-edit
                    </v-icon>
                </div>
            </template>
            <template v-slot:no-data>
                <span>Este Quiz ainda não possui perguntas!</span>
            </template>
        </v-data-table>
        <div data-app="true">
          <v-dialog v-model="dialog" persistent>
            <div style="display:flex;justify-content:center;align-items:center;flex-direction:column">
              <respostas-aceitas 
                titulo="Respostas Aceitas"
                :editedObject="editedItem.respostasaceitas"
                :answer="editedItem.respostascorretas"
                @new="(dados) => adicionarOpcao(dados)"
                @set="(dados) => atualizarResposta(dados)"
                @del="(dados) => removerOpcao(dados)"
                @save="closeAndSave()"
                @cancel="closeAndCancel()"/>
            </div>
          </v-dialog>
        </div>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
            <span>{{ snackText }}</span>
            <v-btn class="mx-1" style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>        
    </v-card>
</template>
<script>
import RespostasAceitas from '@/components/old/RespostasAceitas.vue';
import axios from 'axios'
export default {
  components: { RespostasAceitas },
    props:["id","titulo","perguntas"],
    data: () => ({
        search: '',
        headers: [
            {
                text: 'Pergunta',
                value: 'texto',
                sortable: true
            },
            {
                text: 'Respostas Aceitas',
                value: 'respostasaceitas',
                sortable: false
            },
            {
                text: 'Respostas Corretas',
                value: 'respostascorretas',
                sortable: false
            },
            { text: 'Actions', value: 'actions', sortable: false , width: "150px"},
        ],
        dialog: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {
            id: 0,
            texto: '',
            ativo: true,
            respostasaceitas: {},
            respostascorretas: {}
        }
    }),
    created () {
        
    },

    methods: {
        atualizarResposta(item) {
            this.editedItem.respostascorretas = {}
            this.editedItem.respostascorretas[item] = this.editedItem.respostasaceitas[item]
        },
        adicionarOpcao(item) {
            var n = Object.keys(this.editedItem.respostasaceitas).length
            var chr = String.fromCharCode(97 + n);
            this.editedItem.respostasaceitas[chr] = item
        },
        removerOpcao(item) {
            var newObject = {}
            var keys = Object.keys(this.editedItem.respostasaceitas)
            var chrIndex = 97
            var chr = String.fromCharCode(chrIndex);
            for (var i = 0; i < keys.length; i++) {
                if (keys[i]!=item) {
                    newObject[chr] = this.editedItem.respostasaceitas[chr]
                    chr = String.fromCharCode(++chrIndex);
                }
            }
            this.editedItem.respostasaceitas = newObject
            //delete this.editedItem.respostasaceitas[item]
        },
        editarObjeto(item) {
            this.editedIndex = this.perguntas.indexOf(item);
            // this.editedItem = Object.assign({}, item);
            this.editedItem = JSON.parse(JSON.stringify(item));
            this.dialog=true
        },
        editarRespostasAceitas(item) {
            this.editedIndex = this.perguntas.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },
        salvarRespostaAceita(respostaaceita) {
            console.error(JSON.stringify(respostaaceita))
            this.editedItem.respostasaceitas[respostaaceita.item] = respostaaceita.texto
            if (respostaaceita.correta) {
                this.editedItem.respostascorretas = {}
                this.editedItem.respostascorretas[respostaaceita.item] = respostaaceita.texto
            } else {
                delete this.editedItem.respostascorretas[respostaaceita.item]
            }
        },
        editItem (item) {
            this.editedIndex = this.perguntas.indexOf(item);
            this.editedItem = Object.assign({}, item);
        },
        deleteItem (item) {
            const index = this.perguntas.indexOf(item);
            confirm('Are you sure you want to delete this item?') && this.perguntas.splice(index, 1);
        },
        close () {
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300)
        },
        addNew() {
            const addObj = Object.assign({}, this.defaultItem);
            addObj.id = this.perguntas.length + 1;
            this.perguntas.unshift(addObj);
        },
        closeAndCancel() {
            this.dialog = false
            this.close()
        },
        closeAndSave() {
            this.dialog = false
            this.save()
        },
        save () {
            if (this.editedIndex > -1) {
                if (!this.editedItem.respostasaceitas) {
                    this.editedItem.respostasaceitas = {}
                }
                if (!this.editedItem.respostascorretas) {
                    this.editedItem.respostascorretas = {}
                }
                Object.assign(this.perguntas[this.editedIndex], this.editedItem)
            }
            const token = localStorage.getItem('token')
            if (token) {
                axios.defaults.headers.common['x-access-token'] = token
            }
            const tkapien = require('../../constantes')

            // axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apiesback.economysoftware.com.br'
            let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
            axios.post(baseURL+"/tutorial/upinsert", {
                idioma: 'pt-BR',
                uid:1,
                id: this.id,
                pergunta: {
                    id: this.editedItem.id>0?this.editedItem.id:null,
                    texto: this.editedItem.texto,
                    respostasaceitas: this.editedItem.respostasaceitas,
                    respostascorretas: this.editedItem.respostascorretas
                }
            },{headers:{'x-access-token':tkapien.tokenapies}}).then((response) => {
                if (response.status == 200) {
                // this.tutoriais = response.data
                this.snack = true
                this.snackColor = 'green'
                this.snackText = 'Pergunta salva.'
                }
                this.close();
            })
            .catch((error) => {
                this.close();
                console.log(error);
            });
        },
    },    
}
</script>
<style scoped>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #555;
  color: #fff;
}
.w-100 {
  width: 100%
}
</style>