<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-left wrap>
      <!-- Tutoriais cadastrados -->
      <v-flex xs12 md12>
        <v-card>
          <v-card-title>
            <span class="toolbar-title">Tutoriais Cadastrados</span>
          </v-card-title>


         

          <div style="height:50px;display:flex;background-color:#00b6e7;">
            <div style="width:80%;height:100%;display:flex;align-items:center;padding-left:20px;">
              <select v-model="sistemaselecionado" class="styled" style="border:1px solid black;width:250px;height:30px;background-color:white;">
                <option disabled value="0">Escolha um Sistema</option>
                <option value="1">Ficha Digital</option>
                <option value="2">Cardapio</option>
                <option value="3">FD Guest</option>
              </select>
            </div>
            <div style="width:10%;height:100%;display:flex;align-items:center;">
              <v-btn small elevation="" color="" class="" @click="pesquisar()">Pesquisar</v-btn>
            </div>
            <div style="width:10%;height:100%;display:flex;align-items:center;">
              <v-btn small elevation="" color="" class="" @click="cadastrarNovoTutorial()">Novo Tutorial</v-btn>
            </div>
            
          </div>

          <v-data-table 
            :headers="headers" 
            :items="tutoriais" 
            :search="search" 
            class="elevation-1" 
            fixed-header 
            :items-per-page="-1"
            disable-pagination
          >
            <template v-slot:[`item.sistema`]="{ item }">
              <v-text-field v-model="editedItem.sistema" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
              <span v-else>{{
                item.sistema === 1 ? "Ficha Digital":
                    item.sistema === 2?"Cardápio Digital":
                        item.sistema === 3? "FD Guest":""
                }}</span>
            </template>
            <template v-slot:[`item.titulo`]="{ item }">
              <v-text-field v-model="editedItem.titulo" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
              <span v-else>{{item.titulo}}</span>
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <v-text-field v-model="editedItem.descricao" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
              <span v-else>{{item.descricao}}</span>
            </template>
            <template v-slot:[`item.video`]="{ item }">
              <v-text-field v-model="editedItem.video" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
              <span v-else>{{item.video}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="item.id === editedItem.id">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green"  @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)"  title="Editar">
                  mdi-pencil
                </v-icon>
                <v-icon color="blue"  @click="view(item)" title="Visualizar">
                  mdi-eye-outline
                </v-icon>
                <v-icon color="red" @click="editarQuiz(item)" title="Editar Perguntas">
                  mdi-playlist-edit
                </v-icon>
              </div>
            </template>
          </v-data-table>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn class="mx-1" style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
              </div>
          </v-snackbar>
        </v-card>
        <div data-app="true">
          <v-dialog v-model="dialog">
            <div>
              <Perguntas :id="id" :titulo="titulo" :perguntas="perguntas" @salvar="salvarPerguntas()"
                @fechar="dialog=false"/>
            </div>
          </v-dialog>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import Perguntas from '@/components/old/Perguntas.vue'
import { tokenapies } from '@/constantes.js'
// import dayjs from 'dayjs'
export default {
  components: {
    Perguntas
  },
  data: () => ({
    sistemaselecionado:0,
    snack: false,
    snackColor: "",
    snackText: "",
    search: "",
    tutoriais: [],
    id: 0,
    titulo: '',
    perguntas: [],
    dialog: false,
    headers: [
      { text: "Sistema", value: "sistema" },
      { text: "Título", value: "titulo" },
      { text: "Descrição", value: "descricao" },
      { text: "Id do Video", value: "video"},
      { text: 'Ações', value: 'actions', sortable: false , width: "150px"},
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      id: 0,
      sistema: '',
      titulo: '',
      video: ''
    },    
  }),
  computed: {

  },
  watch: {

  },
  methods: {
    pesquisar(){
      if(this.sistemaselecionado > 0 ){
        this.carregarTutoriais();
      }else{
        alert("Escolha um Sistema!")
      }
    },
    editarQuiz(item) {
      this.id = item.id
      this.titulo = item.titulo
      this.perguntas = item.perguntas
      this.dialog = true
    },
    editItem (item) {
      this.editedIndex = this.tutoriais.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    deleteItem (item) {
      const index = this.tutoriais.indexOf(item);
      confirm('Are you sure you want to delete this item?') && this.tutoriais.splice(index, 1);
    },
    close () {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    addNew() {
       const addObj = Object.assign({}, this.defaultItem);
       addObj.id = this.tutoriais.length + 1;
       this.tutoriais.unshift(addObj);
    },
    cadastrarNovoTutorial(){
      let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'

      let p1 ={'a':"a","b":"b","c":"c"}
      let p1respcerta = {"a":"a"}
      let p2 ={'a':"a","b":"b","c":"c"}
      let p2respcerta = {"a":"a"}
      let p3 ={'a':"a","b":"b","c":"c"}
      let p3respcerta = {"a":"a"}
      let perguntas = [
        {texto:'P1?', p1 , p1respcerta },
        {texto:'P2?', p2 , p2respcerta },
        {texto:'P3?', p3 , p3respcerta },
        
      ]
      
      const token = localStorage.getItem('tktaskcheck');
      
      let textobase = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      axios.post(baseURL+"/tutorial/novo", {
        idioma: 'pt-BR', uid:1,sistema:this.sistemaselecionado,video:'Padrao'+textobase,titulo:'Padrao'+textobase,descricao:'Padrao'+textobase,ativo:true,perguntas:perguntas
      },{headers:{'x-access-token':token}}
      ).then((response) => {
        if (response.status == 200) {
          this.carregarTutoriais();
        }
      })
      .catch((error) => {
          console.log(error)
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tutoriais[this.editedIndex], this.editedItem)
      }
      const token = localStorage.getItem('tktaskcheck')
      if (token) {
          axios.defaults.headers.common['x-access-token'] = token
      }
      // axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      axios.post(baseURL+"/tutorial/upinsert", {
        idioma: 'pt-BR',
        id: this.editedItem.id,
        titulo: this.editedItem.titulo,
        descricao: this.editedItem.descricao,
        video: this.editedItem.video,
        uid:1
      },{headers:{'x-access-token':token}}
      ).then((response) => {
        if (response.status == 200) {
          // this.tutoriais = response.data
          this.snack = true
          this.snackColor = 'green'
          this.snackText = 'Tutorial salvo.'
        }
        this.close();
      })
      .catch((error) => {
        this.close();
        console.log(error);
      });
    },
    view(item){
      this.$router.push("/tutorial/"+item.id)
    },
    carregarTutoriais() {
      const token = localStorage.getItem('token')
      if (token) {
          axios.defaults.headers.common['x-access-token'] = token
      }
      // axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      let baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3343' : 'https://apies.economysoftware.com.br'
      axios.post(baseURL+"/tutorial/select", {idioma: 'pt-BR', sistema: this.sistemaselecionado,uid:1},{headers:{'x-access-token':tokenapies}})
      .then((response) => {
        if (response.status == 200) {
          this.tutoriais = response.data
        }
      })
      .catch((error) => {
        console.log(error);
      });
      // this.tutoriais = [
      //   {
      //     "id": 1,
      //     "sistema": {
      //       "id": 1,
      //       "nome": "Ficha Digital",
      //       "sigla": "FDI",
      //       "descricao": "Sistema Ficha Digital",
      //       "ativo": true
      //     },
      //     "link": "https://www.youtube.com/watch?v=OeEzmPs7i_U",
      //     "chave": "FDTRAINING",
      //     "descricao": "Introdução ao Ficha Digital",
      //     "ativo": true,
      //     "perguntas": [
      //       {
      //         "id": 15,
      //         "texto": "Como chama o sistema de checkin digital?",
      //         "respostasaceitas": {
      //           "a": "FDGuest",
      //           "b": "Ficha",
      //           "c": "Cardapio"
      //         },
      //         "respostascorretas": {
      //           "b": "Ficha"
      //         },
      //         "ativo": true
      //       },
      //       {
      //         "id": 16,
      //         "texto": "Qual a empresa que produz o Ficha Digital?",
      //         "respostasaceitas": {
      //           "a": "Esmeralda",
      //           "b": "Economy",
      //           "c": "Xpto"
      //         },
      //         "respostascorretas": {
      //           "b": "Economy"
      //         },
      //         "ativo": true
      //       },
      //       {
      //         "id": 17,
      //         "texto": "Com quais PMS o FD se integra?",
      //         "respostasaceitas": {
      //           "a": "Totvs",
      //           "b": "Hits",
      //           "c": "Ambos"
      //         },
      //         "respostascorretas": {
      //           "c": "Ambos"
      //         },
      //         "ativo": true
      //       }
      //     ]
      //   },
      //   {
      //     "id": 2,
      //     "sistema": {
      //       "id": 1,
      //       "nome": "Ficha Digital",
      //       "sigla": "FDI",
      //       "descricao": "Sistema Ficha Digital",
      //       "ativo": true
      //     },
      //     "link": "https://www.youtube.com/watch?v=OeEzmPs7i_U",
      //     "chave": "FDPREENCHER",
      //     "descricao": "Preenchendo os dados no Ficha Digital",
      //     "ativo": true,
      //     "perguntas": [
      //       {
      //         "id": 10,
      //         "texto": "Como chama o sistema de checkin digital?",
      //         "respostasaceitas": {
      //           "a": "FDGuest",
      //           "b": "Ficha",
      //           "c": "Cardapio"
      //         },
      //         "respostascorretas": {
      //           "b": "Ficha"
      //         },
      //         "ativo": true
      //       },
      //       {
      //         "id": 11,
      //         "texto": "Qual a empresa que produz o Ficha Digital?",
      //         "respostasaceitas": {
      //           "a": "Esmeralda",
      //           "b": "Economy",
      //           "c": "Xpto"
      //         },
      //         "respostascorretas": {
      //           "b": "Economy"
      //         },
      //         "ativo": true
      //       },
      //       {
      //         "id": 12,
      //         "texto": "Com quais PMS o FD se integra?",
      //         "respostasaceitas": {
      //           "a": "Totvs",
      //           "b": "Hits",
      //           "c": "Ambos"
      //         },
      //         "respostascorretas": {
      //           "c": "Ambos"
      //         },
      //         "ativo": true
      //       },
      //       {
      //         "id": 13,
      //         "texto": "Quem é Economy?",
      //         "respostasaceitas": {
      //           "tipo": "SIM_NAO"
      //         },
      //         "respostascorretas": null,
      //         "ativo": true
      //       },
      //       {
      //         "id": 14,
      //         "texto": "Como chama o sistema de checkin digital?",
      //         "respostasaceitas": {
      //           "a": "Esmeralda",
      //           "b": "Economy",
      //           "c": "Ambas"
      //         },
      //         "respostascorretas": {
      //           "c": "Ambas"
      //         },
      //         "ativo": true
      //       }
      //     ]
      //   }
      // ]
    },
  },
  mounted() {
    if(this.sistemaselecionado > 0 ){
      this.carregarTutoriais();
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #555;
  color: #fff;
}
.w-100 {
  width: 100%
}

.styled select {
  background: transparent;
  width: 150px;
  font-size: 16px;
  border: 1px solid #eff3e7;
  height: 34px;
}
.styled {
  margin: 50px;
  width: 120px;
  height: 34px;
  border: 1px solid #111;
  border-radius: 3px;
  overflow: hidden;
  background: url(http://www.stackoverflow.com/favicon.ico) 96% / 20% no-repeat #eee;
}
</style>